import UploadImageConfirmationModal from 'components/UploadImageConfirmationModal';
import { useCreatorProfile } from 'context/CreatorProfileProvider';
import useUploadImage from 'hooks/useUploadImage';
import EmptyUserIcon from 'images/EmptyUserIcon';
import ProfileCameraIcon from 'images/ProfileCameraIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ImgCustom } from '..';
import { getEnv } from '../../configs/env';
import { acceptedImageTypes, decryptPath, linkS3 } from 'utils';
import './index.scss';
import { useForm } from 'react-hook-form';
import avatarProfile from 'images/AvatarProfile.png';

export default function ProfileAvatar({ name = 'avatar', defaultValue, maxSize, isSelected, setAvatarTmp, setErrorAvar }) {
  const { setValue, setError, clearErrors, errors } = useCreatorProfile();
  const [isOpen, setIsOpen] = useState(false);
  const { setSelectedFile, preview, destroyState, setPreview } = useUploadImage();
  // const userState = useSelector(state => state.users);
  const { data: userProfile } = useSelector((state) => state.users);
  const labelForInputFileRef = useRef({});
  const { t } = useTranslation();
  
  // const {
  //   formState: { errors },
  //   setValue,
  //   setError,
  //   clearErrors,
  // } = useForm({
  //   mode: 'all',
  // });

  useEffect(() => {
    setPreview(`${userProfile?.avatar || defaultValue}`);
  }, [userProfile]);

  useEffect(() => {
    !preview && setValue('avatar', '');
  }, [preview]);

  const onChangeFile = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      setValue('avatar', '');
      return;
    }
    //validateTypeFile(files[0]);
    if (!acceptedImageTypes.includes(files[0]['type'])) {
      setError('avatar', {
        type: 'format',
        message: ('ValidateMsg.C090_VALIDATE_IMAGE'),
      });
      setErrorAvar(t('ValidateMsg.C090_VALIDATE_IMAGE'));
      destroyState();
    } else if (files[0].size > maxSize) {
      setError('avatar', {
        type: 'format',
        message: ('ValidateMsg.imgProfileMaxSize'),
      });
      setErrorAvar(t('ValidateMsg.imgProfileMaxSize'));
      destroyState();
    } else {
      clearErrors('avatar');
      setErrorAvar('');

      setSelectedFile(files[0]);
      setValue('avatar', files[0]);
      setAvatarTmp && setAvatarTmp(files[0]);
    }
    setIsOpen(false);
  };

  const validateTypeFile = (file) => {
    if (!acceptedImageTypes.includes(file['type'])) {
      setError('avatar', {
        type: 'format',
        message: t('ValidateMsg.C090_VALIDATE_IMAGE'),
      });
      setErrorAvar(t('ValidateMsg.C090_VALIDATE_IMAGE'));
      destroyState();
    } else if (file.size > maxSize) {
      setError('avatar', {
        type: 'format',
        message: t('ValidateMsg.imgProfileMaxSize'),
      });
      setErrorAvar(t('ValidateMsg.imgProfileMaxSize'));
      destroyState();
    } else {
      clearErrors('avatar');
      setErrorAvar('');
    }
  };

  const openChangeFileModal = () => {
    setIsOpen(true);
  };

  const onResolve = () => {
    labelForInputFileRef?.current?.click();
  };

  const onReject = (type) => {
    if (type === 'dismiss') {
      setIsOpen(false);
    } else {
      setAvatarTmp(null);
      destroyState();
      setIsOpen(false);
    }
  };

  const changeAvatarJsx = () => {
    return (
      <React.Fragment>
        <span
          className='position-absolute camera-bg d-inline-block right bottom'
          onClick={openChangeFileModal}>
          <ProfileCameraIcon />
        </span>
        <label htmlFor='avatar' className='d-none' ref={labelForInputFileRef}>
          <input
            type='file'
            id='avatar'
            className='d-none'
            onChange={onChangeFile}
            name={name}
            accept={acceptedImageTypes.map((item) => item).join(',')}
          />
        </label>
      </React.Fragment>
    );
  };
  const jsx = changeAvatarJsx();

  return (
    <React.Fragment>
      <UploadImageConfirmationModal
        isOpen={isOpen}
        onResolve={onResolve}
        onReject={onReject}
        isSelected={isSelected}
        defaultValue={defaultValue}
        preview={preview}
      />
      <div className='position-relative z-index-20 avatar-wrapper'>
        {!preview && (
          <div className='avatar-container position-relative d-flex justify-content-between'>
            <img src={avatarProfile} alt="avatarProfile" className='avatar' />
            {jsx}
            {userProfile?.is_link === 1 && (
              <button
                className='navigate-fan btn align-items-center'
                onClick={() =>
                  (window.location.href = `${getEnv(
                    'REACT_APP_FAN_URL'
                  )}/my-page?is_link=1&email=${userProfile?.email}`)
                }>
                {t('ProfileSetting.navigateFan')}
              </button>
            )}
          </div>
        )}
        {preview && (
          <div className='avatar-container position-relative d-flex justify-content-between align-items-center'>
            <ImgCustom
              src={preview}
              style={{ opacity: `${userProfile?.is_avatar_reviewing === 1 && '0.4'}` }}
              alt={name}
              className='avatar-img object-fit position-relative avatar'
              onError={destroyState}
            />
            {userProfile?.is_avatar_reviewing === 1 &&
              <div className='loadding-upload-avatar'>{t('ProfileSetting.review')}</div>
            }
            {jsx}
            {userProfile?.is_link === 1 && (
              <button
                className='navigate-fan btn align-items-center'
                onClick={() =>
                  (window.location.href = `${getEnv(
                    'REACT_APP_FAN_URL'
                  )}/my-page?is_link=1&email=${userProfile?.email}`)
                }>
                {t('ProfileSetting.navigateFan')}
              </button>
            )}
          </div>
        )}
        <p className='fansite-form-input__error text-danger text-start avatar-error'>
          {t(errors?.avatar?.message)}
        </p>
      </div>
    </React.Fragment>
  );
}
