import UploadImageConfirmationModal from 'components/UploadImageConfirmationModal';
import { useCreatorProfile } from 'context/CreatorProfileProvider';
import useUploadImage from 'hooks/useUploadImage';
import BackgroundImageIcon from 'images/BackgroungImageIcon';
import ProfileCameraIcon from 'images/ProfileCameraIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { acceptedImageTypes, decryptPath, linkS3 } from 'utils';
import { ImgCustom } from '..';
import './index.scss';
import { useForm } from 'react-hook-form';
import { StyledBG } from './styled';

export default function ProfileBackgroundImage({
  name,
  defaultValue,
  maxSize,
  isSelected,
  setBgTmp,
  setErrorBG,
  ...props
}) {
  const { setSelectedFile, preview, destroyState, setPreview } = useUploadImage();
  const { setValue, setError, clearErrors, errors } = useCreatorProfile();
  // const userState = useSelector(state => state.users);
  const { data: userProfile } = useSelector((state) => state.users);
  const [isOpen, setIsOpen] = useState(false);
  const labelForInputFileRef = useRef({});
  const { t } = useTranslation();

  useEffect(() => {
    setPreview(`${userProfile?.bg_img || defaultValue}`);
  }, [userProfile]);

  useEffect(() => {
    !preview && setValue('bg_img', '');
  }, [preview]);

  const onChangeFile = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      setValue('bg_img', '');
      return;
    }
    //validateTypeFile(files[0]);
    if (!acceptedImageTypes.includes(files[0]['type'])) {
      setError('bg_img', {
        type: 'format',
        message: `${('ValidateMsg.C090_VALIDATE_IMAGE')}`,
      });
      setErrorBG(t('ValidateMsg.C090_VALIDATE_IMAGE'));
      destroyState();
    } else if (files[0].size > maxSize) {
      setError('bg_img', {
        type: 'format',
        message: `${('ValidateMsg.imgProfileMaxSize')}`,
      });
      setErrorBG(t('ValidateMsg.imgProfileMaxSize'));
      destroyState();
    } else {
      clearErrors('bg_img');
      setErrorBG('')

      setSelectedFile(files[0]);
      setValue('bg_img', files[0]);
      setBgTmp && setBgTmp(files[0]);
    }
    setIsOpen(false);
    // setSelectedFile(files[0]);
    // setValue('bg_img', files[0]);
    // setBgTmp && setBgTmp(files[0]);
    // setIsOpen(false);
  };

  // const validateTypeFile = (file) => {
  //   if (!acceptedImageTypes.includes(file['type'])) {
  //     setError('bg_img', {
  //       type: 'format',
  //       message: `${t('ValidateMsg.C090_VALIDATE_IMAGE')}`,
  //     });
  //     setErrorBG(t('ValidateMsg.C090_VALIDATE_IMAGE'));
  //     destroyState();
  //   } else if (file.size > maxSize) {
  //     setError('bg_img', {
  //       type: 'format',
  //       message: `${t('ValidateMsg.imgProfileMaxSize')}`,
  //     });
  //     setErrorBG(t('ValidateMsg.imgProfileMaxSize'));
  //     destroyState();
  //   } else {
  //     clearErrors('bg_img');
  //     setErrorBG('')
  //   }
  // };

  const openChangeFileModal = () => {
    setIsOpen(true);
  };

  const onResolve = () => {
    labelForInputFileRef?.current?.click();
  };

  const onReject = (type) => {
    if (type === 'dismiss') {
      setIsOpen(false);
    } else {
      setBgTmp(null);
      destroyState();
      setIsOpen(false);
    }
  };
  const changeImgJsx = () => (
    <React.Fragment>
      <span
        className='position-absolute edit-cover d-inline-block'
        onClick={openChangeFileModal}>
        <ProfileCameraIcon />
      </span>
      <span className='w-h-bg'>720x360</span>
      <label htmlFor='bg_img' className='d-none' ref={labelForInputFileRef}>
        <input
          type='file'
          id='bg_img'
          className='d-none'
          onChange={onChangeFile}
          name={name}
          accept={acceptedImageTypes.map((item) => item).join(',')}
        />
      </label>
    </React.Fragment>
  );
  const jsx = changeImgJsx();
  return (
    <React.Fragment>
      <UploadImageConfirmationModal
        isOpen={isOpen}
        onResolve={onResolve}
        onReject={onReject}
        isSelected={isSelected}
        defaultValue={defaultValue}
        preview={preview}
      />
      {!preview && (
        <div className='image-container d-flex align-items-center justify-content-center position-relative'>
          <BackgroundImageIcon />
          {jsx}
        </div>
      )}
      {preview && (
        <div className='bg-img position-relative'>
          <StyledBG.BlueImg url={decryptPath(preview, { isMini: true })} height='100%' width="100%">
            <div className='bg-img'></div>
            <div>{jsx}</div>
            <ImgCustom
              //screen='home_banner_720_200'
              src={preview}
              className='w-100 bg-img'
              alt={name || 'background'}
              onError={destroyState}
              style={{
                objectFit: 'contain',
                position: 'absolute',
                zIndex: '14',
                background: 'transparent',
                opacity: `${userProfile?.is_cover_reviewing === 1 ? '0.4' : ''}`
              }}
            />
            {userProfile?.is_cover_reviewing === 1 &&
              <div className='loadding-upload'>{t('ProfileSetting.review')}</div>
            }
          </StyledBG.BlueImg>
        </div>
      )}
      <p className='fansite-form-input__error text-danger text-end'>
        {t(errors?.bg_img?.message)}
      </p>
    </React.Fragment>
  );
}
