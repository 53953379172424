// import CreatorLiveStreamingList from 'components/CreatorLiveStreamingList';
import { ImgCustom, LayoutCreatorRight, PopupLoading } from 'components';
import HomeQuickViewImages from 'components/HomeQuickViewImages';
import { getEnv } from 'configs/env';
import { newPathUser } from 'constants/layout/constant';
import useUserRole from 'hooks/useUserRole';
import {
  FlagIcon,
  NewCrown,
  ShareIcon,
  ThreePointIcon,
  BlockIcon,
  MessageIcon,
  MessageNew1808,
} from 'images';
import AvatarDemo from 'images/Avatar.png';
import BackgroundImageIcon from 'images/BackgroungImageIcon';
import PencilIcon from 'images/PencilIcon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FACEBOOK_IMG,
  INSTAGRAM_IMG,
  STORAGE,
  TWITTER_IMG,
  YOUTUBE_IMG,
  THREADS_IMG,
  AVATAR_DEL_USER,
  AVATAR_BLOCK_USER,
  AVATAR_DEFAULT,
  AVATAR_DELETE,
  AVATAR_BLOCK,
} from 'utils';
import { decryptPath, formatCoin, isAdmin, isAgency, setOverflowTablet } from 'utils/utils';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import PopupSharePost from '../Popup/SharePost';
import './index.scss';
import { ListHighLight } from './styles';
import {
  fanFollowCreator,
  getIDHomePage,
  getListPostCreator,
  getUserInfoOthers,
  resetIDHomePage,
} from 'store/actions/usersNew';
import avatarProfile from 'images/AvatarProfile.png';
import { toast } from 'react-toastify';
import { isMobile, isSafari, isTablet } from 'react-device-detect';
import PopupViewFullIMG from 'components/Account/PopupViewFullIMG';
import { logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';
import { ReadMoreHomePage } from 'components/ReadMoreHomePage';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import {
  blockOrUnblockFanMessage,
  clearScrollBackFollow,
  setDataItemFollowUser,
} from 'store/actions/users';
import useCheckVersionBrowser from 'hooks/useCheckVersionBrowser';
import { clearIdScrollBackPackageSubAndData } from 'store/actions/lives';
import { clearIdScrollBackSellPackageAndData } from 'store/actions/sellPost';
import MarkDownRead from 'components/MarkDownRead';

const HomePageHeader = ({ creatorProfile, setCreatorProfile }) => {
  const { data: userProfile, dataUser, idSearchUser } = useSelector((state) => state.users);
  const { dataInfoOther, idHomePage, paginationPostHome } = useSelector(
    (state) => state.usersNew
  );
  const { loading, listLiveSteams } = useSelector((state) => state.creator);
  const { isFan, isIdol } = useUserRole();
  const dispatch = useDispatch();
  const [popupCopyLink, setPopupCopyLink] = useState();
  const [popupBlockUser, setPopupBlockUser] = useState(false);
  const [blockStatus, setBlockStatus] = useState();
  const widthScreen = window.innerWidth;
  const { account_id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const scrollRef = useHorizontalScroll();
  const [isLoading, setLoading] = useState(false);
  const versionSafariSupport = 15;
  const versionBrowser = useCheckVersionBrowser();
  const loadingLazySupport = () => {
    return parseInt(versionBrowser?.split(' ')[1]) < versionSafariSupport;
  };
  // const [creatorProfile, setCreatorProfile] = useState();
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };
  const handleBlockUser = () => {
    setPopupBlockUser(true);
  };
  const handleBlockSuccess = ({ data: dataBlock, success }) => {
    if (success && dataBlock) {
      setBlockStatus(+dataBlock?.is_blocked);
      setPopupBlockUser(false);
      dispatch(getUserInfoOthers(dataInfoOther?.account_id));
    }
    setLoading(false);
  };
  const [hoverId, setHoverId] = useState(false);
  const [showPopupFull, setShowPopupFull] = useState({
    status: false,
    idx: 0,
    dataItem: [],
    iSMany: 1,
  });
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [isVisibleMenu, setIsVisbleMenu] = useState(false);

  useEffect(() => {
    setBlockStatus(dataInfoOther?.is_blocked);

    if (isVisibleMenu) {
      document.getElementById('root').style.overflow = 'hidden';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
    }
  }, [isVisibleMenu]);

  const accountInfo = [
    {
      label: `${t('homePageHeader.following')}`,
      id: 'follow',
      value: `${formatCoin(creatorProfile?.followings_count)}` || `0  `,
    },
    {
      label: `${t('homePageHeader.follower')}`,
      id: 'followers',
      value: `${formatCoin(creatorProfile?.followers_count)} ` || `0 `,
    },
    {
      // số lượng bài post
      label: `${t('homePageHeader.totalPost')}`,
      id: 'total_posts',
      value: `${formatCoin(creatorProfile?.post_count)}` || `0`,
    },
    {
      // số lượng bài post
      label: `${t('homePageHeader.limiteded')}`,
      id: 'limiteded',
      value: `${formatCoin(creatorProfile?.post_limit_count)}` || `0 `,
    },
    // ...(creatorProfile?.package || [])?.map((group) => ({
    //   label: group?.title,
    //   id: group?.id,
    //   value: group?.limited ? formatCoin(group?.limited) : '',
    // })),
  ];
  const onUserNavigate = () => {
    if (creatorProfile?.status !== 1) {
      toast.error(t('Creator.Message.featureNotAvailable'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'colored',
        className: 'display-linebreak',
        enableMultiContainer: true,
        icon: ({ theme, type }) => (
          <svg
            width={18}
            height={18}
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
              stroke='#DC143C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.25 6.75L6.75 11.25'
              stroke='#DC143C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.25 11.25L6.75 6.75'
              stroke='#DC143C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ),
      });
    } else {
      if (creatorProfile?.id !== dataUser?.id) {
        dispatch(clearIdScrollBackSellPackageAndData());
        navigate(
          `${newPathUser}${
            creatorProfile?.account_id === dataUser?.account_id
              ? dataUser?.id
              : creatorProfile?.id
          }/plan-list`
        );
      } else {
        dispatch(clearIdScrollBackPackageSubAndData());
        navigate(`${newPathUser}profile/subscription`);
      }
    }
  };

  const onUserNavigate1 = (link, setState) => {
    if (dataUser?.status !== 1 || creatorProfile?.status !== 1) {
      toast.error(t('Creator.Message.featureNotAvailable'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'colored',
        className: 'display-linebreak',
        enableMultiContainer: true,
        icon: ({ theme, type }) => (
          <svg
            width={18}
            height={18}
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
              stroke='#DC143C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.25 6.75L6.75 11.25'
              stroke='#DC143C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.25 11.25L6.75 6.75'
              stroke='#DC143C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ),
      });
    } else {
      navigate(link, { state: setState });
    }
  };

  const isLive = useMemo(
    () => listLiveSteams?.data?.some((item) => item?.status === 3),
    [listLiveSteams]
  );

  const handleFollowCreator = () => {
    const payload = {
      type: 1,
      page: 1,
      limit: 10,
      id: dataInfoOther?.id,
    };
    creatorProfile &&
      dispatch(
        fanFollowCreator(creatorProfile?.id, (data) => {
          setCreatorProfile({ ...creatorProfile, is_followed: data.is_followed });
          if (idSearchUser) {
            dispatch(setDataItemFollowUser(creatorProfile?.id, data));
          }
          //dispatch(getListPostCreator(payload, false));
        })
      );
  };

  // const handleFollowOrUnFollowTwitter = () => {
  //   setLoading(true);
  //   creatorProfile?.twitter_status_follow === 1
  //     ? dispatch(
  //         unFollowTwitter(
  //           creatorProfile.id,
  //           () => {
  //             pushNotify('success', t('ValidateMsg.TWITTER_UNFOLLOW_SUCCESS'));
  //             setCreatorProfile({ ...creatorProfile, twitter_status_follow: 0 });
  //             setLoading(false);
  //           },
  //           () => setLoading(false)
  //         )
  //       )
  //     : dispatch(
  //         followTwitter(
  //           creatorProfile.id,
  //           () => {
  //             pushNotify('success', t('ValidateMsg.TWITTER_FOLLOW_SUCCESS'));
  //             setCreatorProfile({ ...creatorProfile, twitter_status_follow: 1 });
  //             setLoading(false);
  //           },
  //           () => setLoading(false)
  //         )
  //       );
  // };

  const buttonRanking = (link, setState = '') => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 9 }}>
        {/* comment isFan 7/3/2023 */}
        {/* {isFan && creatorProfile?.twitter_link_status === 1 && (
          <button
            className={`rank-button twitter-follow ${isAdmin ? 'disabled' : ''}`}
            onClick={handleFollowOrUnFollowTwitter}>
            <TwitterIcon className='twitter-icon' />
            <div className='dsl-t-space'>
              {t(
                creatorProfile?.twitter_status_follow === 1
                  ? 'homePageHeader.twitterUnFollow'
                  : 'homePageHeader.twitterFollow'
              )}
            </div>
          </button>
        )} */}
        <button
          className={`rank-button ${isAdmin ? 'disabled' : ''}`}
          onClick={() => {
            if (idHomePage?.id) {
              dispatch(
                getIDHomePage({
                  id: true,
                  tab: 1,
                  isMedia: idHomePage?.isMedia,
                  page: idHomePage?.page ? idHomePage?.page : paginationPostHome?.current_page,
                })
              );
            } else {
              dispatch(
                getIDHomePage({
                  id: 'null',
                  tab: 1,
                  isMedia: idHomePage?.isMedia,
                  page: idHomePage?.page ? idHomePage?.page : paginationPostHome?.current_page,
                })
              );
            }
            onUserNavigate1(link, setState);
          }}>
          <NewCrown />
          {/* <div>
            <div>{t('homePageHeader.biller')}</div>
            <div>{t('homePageHeader.ranking')}</div>
          </div> */}
        </button>
      </div>
    );
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNavigateFollowPage = (id) => {
    if (creatorProfile?.account_id) {
      if (id === 'follow') {
        dispatch(clearScrollBackFollow());
        navigate(`${newPathUser}follow-list/${creatorProfile?.account_id}`, {
          state: {
            IDUSER: creatorProfile?.account_id,
          },
        });
      }
      if (id === 'followers') {
        dispatch(clearScrollBackFollow());
        navigate(`${newPathUser}follower-list/${creatorProfile?.account_id}`, {
          state: {
            IDUSER: creatorProfile?.account_id,
          },
        });
      }
    }
  };
  const goToNotiPage = () => {
    navigate(`${newPathUser}profile/notification`);
  };

  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.clientHeight;

      if (contentRef.current) {
        const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight);
        const numLines = height / lineHeight;
        setContentHeight(numLines);
      }
    }
  }, [creatorProfile?.description, contentRef.current]);

  const checkStatus = (status) => {
    let tmp = '';
    switch (status) {
      case null: // del
        tmp = AVATAR_DEL_USER
        break;
      case 2: // stop
        tmp = AVATAR_BLOCK_USER
        break;
      default:
        tmp = AVATAR_DEFAULT;
    }
    return tmp;
  };

  return (
    <>
      {isLoading && <PopupLoading className={'popup-loading'} />}
      <div className={`home-info-header bg-white`}>
        {isIdol && [2].includes(userProfile?.status) && (
          <div className='popup-creator-block' />
        )}
        {popupCopyLink && (
          <PopupSharePost
            isVisiblePopup={() => setPopupCopyLink()}
            data={popupCopyLink || {}}
          />
        )}

        {popupBlockUser && (
          <PopupConfirmDelete
            maxWidth={'min-content'}
            title={
              blockStatus === 1
                ? t('Popup.confirmUnBlockMessageUser', {
                    name: dataInfoOther?.account_name,
                  })
                : t('Popup.confirmBlockMessageUser', {
                    name: dataInfoOther?.account_name,
                  })
            }
            setShowPopup={setPopupBlockUser}
            handleOnSubmit={() => {
              setLoading(true);
              dispatch(blockOrUnblockFanMessage(dataInfoOther?.id, handleBlockSuccess));
            }}
          />
        )}

        {showPopupFull.status && (
          <PopupViewFullIMG
            className='popup-post-detail-show-media'
            idx={showPopupFull.idx}
            item={showPopupFull}
            close={() => {
              setShowPopupFull({
                ...showPopupFull,
                status: false,
                dataItem: {},
              });
            }}
          />
        )}

        {/* {isMobile && (
          <>
            <HeaderListIdolV2 />
            <div className='header__mobile_creator d-flex justify-content-between align-items-center'>
              <div className='nav__btn'>
                <MenuIcon onClick={() => setIsVisbleMenu(true)} />
              </div>
              <div
                className='header__mobile_creator__screen-title d-flex justify-content-center align-items-center w-100'
                style={{ fontWeight: 500, fontSize: 20 }}>
                {t('Header.index.home')}
              </div>
            </div>
            {isVisibleMenu && (
              <div className='box-modal'>
                <div className='menu-mobile'>
                  <AccountSidebar isAdmin={isAdmin} isAgency={isAgency} user={dataUser}>
                    <CloseIcon
                      style={{ cursor: 'pointer', zIndex: 10 }}
                      className='close-drawer'
                      onClick={() => setIsVisbleMenu(false)}
                    />
                  </AccountSidebar>
                  <MenuSidebarCustom />
                </div>
                <div className='gray-screen' onClick={() => setIsVisbleMenu(false)}></div>
              </div>
            )}
          </>
        )} */}
        <LayoutCreatorRight
          titlePage={t('Header.index.home')}
          linkBack={`${newPathUser}timeline`}
          className='header-short'></LayoutCreatorRight>
        <div
          className={`home-background-image `}
          style={{ height: `${!isMobile && isSafari && loadingLazySupport() && '218px'}` }}
          onClick={() => {
            creatorProfile?.bg_img !== null &&
              setShowPopupFull({
                ...showPopupFull,
                status: true,
                dataItem: creatorProfile?.bg_img,
                idx: 0,
                iSMany: 1,
              });
          }}>
          {!creatorProfile?.bg_img ? (
            <BackgroundImageIcon className='bg-icon' />
          ) : (
            <ListHighLight.BlueImg
              url={decryptPath(creatorProfile?.bg_img, { isMini: true })}
              height={'100%'}
              width={'100%'}>
              <div className='bg-img bdt'></div>
              <ImgCustom
                screen='home_banner_720_200'
                src={`${creatorProfile?.bg_img}`}
                alt='background'
                className='w-100 h-100'
                avatarErr={false}
                style={{
                  objectFit: 'contain',
                  position: 'absolute',
                  zIndex: '14',
                  background: 'transparent',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  opacity: `${creatorProfile?.is_cover_reviewing === 1 ? '0.4' : ''}`
                }}
              />
              {creatorProfile?.is_cover_reviewing === 1 &&
                <div className='loadding-upload'>{t('ProfileSetting.review')}</div>
              }
            </ListHighLight.BlueImg>
          )}
        </div>

        <div className={`img-and-button-wrapper creator w-100`}>
          <div
            className={`home-avatar-image ${
              (dataUser?.is_live === 1 && dataUser?.id === creatorProfile?.id) ||
              (creatorProfile?.is_live === 1 && dataUser?.id !== creatorProfile?.id)
                ? 'border-red'
                : 'border-white'
            }`}>
            <div className='avatar'>
              {creatorProfile?.avatar ? (
                <>
                <ImgCustom
                  screen='home_avartar_100_100'
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    cursor: !isMobile ? 'pointer' : 'auto',
                    opacity: `${(creatorProfile?.status === 2 || creatorProfile?.status === null) ? '50%' : creatorProfile?.is_avatar_reviewing === 1 ? '0.4' : ''}`
                  }}
                  alt={creatorProfile?.account_name}
                  src={creatorProfile?.avatar ? `${creatorProfile?.avatar}` : AvatarDemo}
                  className='w-100 avatar-home'
                  onClick={() => {
                    if (creatorProfile?.is_live === 1 && dataUser?.id !== creatorProfile?.id) {
                      navigate(`${newPathUser}lives/list/live`);
                    } else {
                      creatorProfile?.avatar !== null &&
                        setShowPopupFull({
                          ...showPopupFull,
                          status: true,
                          dataItem: creatorProfile?.avatar,
                          idx: 0,
                          iSMany: 1,
                        });
                    }
                  }}
                />
                {creatorProfile?.is_avatar_reviewing === 1 &&
                  <div style={{ left: '50%', textAlign: 'center', width: '100%' }} className='loadding-upload-avatar'>{t('ProfileSetting.review')}</div>
                }
                {(creatorProfile?.status === 2 || creatorProfile?.status === null) &&
                  <img className='img-admin' src={creatorProfile?.status === 2 ? AVATAR_BLOCK : AVATAR_DELETE} alt='avatarProfile1' />
                }
                </>
              ) : (
                <img style={{ width: "100%", height: '100%' }} src={checkStatus(creatorProfile?.status)} alt='avatarProfile1' />
              )}
            </div>

            {((dataUser?.is_live === 1 && dataUser?.id === creatorProfile?.id) ||
              (creatorProfile?.is_live === 1 && dataUser?.id !== creatorProfile?.id)) && (
              <button
                onClick={() => {
                  if (creatorProfile?.is_live === 1 && dataUser?.id !== creatorProfile?.id) {
                    navigate(`${newPathUser}lives/list/live`);
                  }
                }}
                className='live-btn btn text-white font-size-14 font-weight-700'>
                Live
              </button>
            )}
          </div>
          {/* name user */}
          {!isMobile && (
            <div className='name-wrap'>
              <div>
                <div className='full-name'>{creatorProfile?.account_name}</div>
                {isFan && buttonRanking(`/ranking-buyer/${account_id}`)}
              </div>
              <div className='full-name japan-name'>{creatorProfile?.account_id}</div>
            </div>
          )}
          {creatorProfile && (
            <div className='control-group react-wrap'>
              <div
                className={`control-group_spacign creator ${
                  (isAdmin || isAgency) && 'disabled'
                }`}>
                <button
                  style={{
                    order: '-1',
                  }}
                  className='share-button'>
                  <ShareIcon
                    className={`share-icon`}
                    style={{
                      color: '#282727',
                    }}
                    onClick={(e) => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        handleSharePost({
                          link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}${
                            creatorProfile?.account_id
                          }`,
                        });
                        e.preventDefault();
                      }
                    }}
                  />
                </button>
                {isIdol &&
                  creatorProfile?.id === dataUser?.id &&
                  buttonRanking(
                    `${newPathUser}ranking-buyer`,
                    creatorProfile?.id ? { account_id: creatorProfile?.id } : {}
                  )}

                {dataUser?.id !== creatorProfile?.id && (
                  <button
                    style={{
                      order: '-1',
                    }}
                    onClick={(e) => {
                      navigate(`${newPathUser}message/${dataInfoOther?.id}`);
                    }}
                    className='message-button'>
                    <MessageNew1808
                      className={`message-icon`}
                      style={{
                        color: '#282727',
                      }}
                    />
                  </button>
                )}

                {creatorProfile?.id !== dataUser?.id && !isAdmin && !isAgency && (
                  <div
                    className={`btn-followOrUnFollow ${
                      creatorProfile?.is_followed === 1 ? 'following' : 'no-follow'
                    } ${
                      creatorProfile?.is_followed === 1 && hoverId === creatorProfile?.id
                        ? 'hover'
                        : ''
                    }`}
                    onMouseOver={() => {
                      if (creatorProfile?.is_followed === 1 && creatorProfile?.id) {
                        !isMobile && setHoverId(creatorProfile.id);
                      }
                    }}
                    onMouseLeave={() => {
                      if (creatorProfile?.is_followed === 1) {
                        setHoverId(false);
                      }
                    }}
                    onClick={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        dataUser?.id !== creatorProfile?.id && handleFollowCreator();
                      }
                    }}>
                    {widthScreen > 991
                      ? creatorProfile?.is_followed === 1
                        ? hoverId === creatorProfile?.id
                          ? t('Creator.FollowList.unfollow')
                          : t('Creator.FollowList.following')
                        : t('Creator.FollowList.followUs')
                      : creatorProfile?.is_followed === 1
                      ? t('Creator.FollowList.following')
                      : t('Creator.FollowList.followUs')}
                  </div>
                )}
                {dataUser?.id !== creatorProfile?.id && (
                  <div className='box-popup d-flex'>
                    <ThreePointIcon
                      style={{ color: '#666565' }}
                      className='three-point-icon'
                      onClick={() => {
                        setIsVisiblePopup(
                          isVisiblePopup === creatorProfile?.id ? -1 : creatorProfile?.id
                        );
                      }}
                      onBlur={() => setIsVisiblePopup(-1)}
                    />
                    {isVisiblePopup === creatorProfile?.id && (
                      <div className='popup-edit-comment' style={{ top: 'calc(100% - 25px)' }}>
                        <>
                          {/* <div
                          className='item'
                          onMouseDown={() => {
                            setIsVisiblePopup(-1);
                            navigate(`${newPathUser}/message/${creatorProfile?.id}`);
                          }}>
                          <Messv2Icon />
                          <span className='text'>{t('HomePageContent.sendMessage')}</span>
                        </div> */}
                          <div
                            className='item'
                            onMouseDown={() => {
                              handleBlockUser();
                            }}>
                            <BlockIcon
                              color={dataInfoOther?.is_blocked === 1 ? '#dc143c' : '#1a1a1a'}
                            />
                            <span className='text'>
                              {dataInfoOther?.is_blocked === 1
                                ? t('comment.listComment.unblock')
                                : t('comment.listComment.block')}
                            </span>
                          </div>
                          <div
                            className='item'
                            onMouseDown={() => {
                              if (!isAuth) {
                                setIsVisiblePopup(-1);
                                dispatch(logUserProfile());
                              } else {
                                setIsVisiblePopup(-1);
                                navigate(`${newPathUser}post-report`, {
                                  state: {
                                    fan_id: creatorProfile?.id,
                                    account_id: creatorProfile?.account_id,
                                  },
                                });
                              }
                            }}>
                            <FlagIcon />
                            <span className='text'>{t('comment.listComment.iconFlag')}</span>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                )}
                {/* comment fan 7/3/2023 */}
                {isFan && (
                  <button
                    className='report-button bg-white rounded-6'
                    onClick={() =>
                      navigate('/post-report', {
                        state: {
                          creator_id: creatorProfile?.id,
                          account_id: creatorProfile?.account_id,
                        },
                      })
                    }>
                    {t('homePageHeader.report')}
                  </button>
                )}
                {creatorProfile?.id === dataUser?.id && (
                  <button
                    className='edit-button text-white'
                    onClick={() => {
                      if (idHomePage?.id) {
                        dispatch(
                          getIDHomePage({
                            id: true,
                            tab: 1,
                            isMedia: idHomePage?.isMedia,
                            page: idHomePage?.page
                              ? idHomePage?.page
                              : paginationPostHome?.current_page,
                          })
                        );
                      } else {
                        dispatch(
                          getIDHomePage({
                            id: 'null',
                            tab: 1,
                            isMedia: idHomePage?.isMedia,
                            page: idHomePage?.page
                              ? idHomePage?.page
                              : paginationPostHome?.current_page,
                          })
                        );
                      }
                      navigate(`${newPathUser}profile-settings`);
                    }}
                    style={{ border: 'none' }}>
                    <PencilIcon />
                    &nbsp;&nbsp;{t('homePageHeader.edit')}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {isMobile && (
          <div className={`name-wrap ${isTablet && 'name-title-account'}`}>
            <div>
              <div className='full-name'>{creatorProfile?.account_name}</div>
              {isFan && buttonRanking(`/ranking-buyer/${account_id}`)}
            </div>
            <div className='full-name japan-name'>{creatorProfile?.account_id}</div>
          </div>
        )}
        <div className='px-3 px-sm-4 home-info-header__body-part'>
          {isMobile ? (
            <div className='dp-colurm-home-page'>
              {creatorProfile?.user_sns?.length > 0 && (
                <div
                  className='list-social-link scroll-bar-custom-horizontal'
                  ref={scrollRef}
                  onClick={() => {
                    !isAuth && dispatch(logUserProfile());
                  }}>
                  {creatorProfile?.user_sns?.map((item, index) => (
                    <a
                      key={index}
                      href={isAuth && item?.url}
                      target='_blank'
                      className={`social-link-item`}
                      rel='noreferrer'>
                      <img
                        src={
                          item?.sns_name === 'youtube'
                            ? YOUTUBE_IMG
                            : item?.sns_name === 'facebook'
                            ? FACEBOOK_IMG
                            : item?.sns_name === 'instagram'
                            ? INSTAGRAM_IMG
                            : item?.sns_name === 'threads'
                            ? THREADS_IMG
                            : TWITTER_IMG
                        }
                        alt={item?.sns_name}
                        className='bg-unset'
                      />
                    </a>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className='dp-flex-home-page'>
              <div
                className='list-social-link scroll-bar-custom-horizontal'
                ref={scrollRef}
                onClick={() => {
                  !isAuth && dispatch(logUserProfile());
                }}>
                {creatorProfile?.user_sns?.map((item, index) => (
                  <a
                    key={index}
                    href={isAuth && item?.url}
                    target='_blank'
                    className='social-link-item'
                    rel='noreferrer'>
                    <img
                      src={
                        item?.sns_name === 'youtube'
                          ? YOUTUBE_IMG
                          : item?.sns_name === 'facebook'
                          ? FACEBOOK_IMG
                          : item?.sns_name === 'instagram'
                          ? INSTAGRAM_IMG
                          : item?.sns_name === 'threads'
                          ? THREADS_IMG
                          : TWITTER_IMG
                      }
                      alt={item?.sns_name}
                      className='bg-unset'
                    />
                  </a>
                ))}
              </div>
            </div>
          )}

          <div className='home-infor-header_infomation flex-wrap mb-2'>
            {accountInfo?.map((inf) => (
              <div
                className={`column ${inf?.id} ${isAdmin && 'disabled'}`}
                key={inf?.id}
                onClick={() => {
                  if (!isAuth) {
                    dispatch(logUserProfile());
                  } else {
                    if (idHomePage?.id) {
                      dispatch(
                        getIDHomePage({
                          id: true,
                          tab: 1,
                          isMedia: idHomePage?.isMedia,
                          page: idHomePage?.page
                            ? idHomePage?.page
                            : paginationPostHome?.current_page,
                        })
                      );
                    } else {
                      dispatch(
                        getIDHomePage({
                          id: 'null',
                          tab: 1,
                          isMedia: idHomePage?.isMedia,
                          page: idHomePage?.page
                            ? idHomePage?.page
                            : paginationPostHome?.current_page,
                        })
                      );
                    }
                    handleNavigateFollowPage(inf?.id);
                  }
                }}>
                <span className='info-label'>{inf?.label}</span>
                <span className='info-value'>{inf?.value}</span>
              </div>
            ))}
          </div>
          {/* <div
            style={{ fontFamily:'sans-serif'}}
            className={`font-size-14 mt-3 introduce ${!isExpanded ? 'cls-title-2nd5' : ''} `}
            onClick={toggleText}
          >
            {creatorProfile?.description || ''}
          </div> */}
          <div
            ref={contentRef}
            className={`font-size-14 mt-3 introduce ${!isExpanded ? 'cls-title-2nd5' : ''} `}
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'sans-serif',
            }}>
            <MarkDownRead content={creatorProfile?.description || ''} />
          </div>
          {contentHeight >= 3 && (
            <p style={{ color: '#1480ff', cursor: 'pointer' }} onClick={toggleText}>
              {!isExpanded && t('ReadMoreLessNew.moreText')}
            </p>
          )}
          {/* List Highlight */}
          {/* {listHighLight.length > 0 && funcRenderHighlight()} */}
          {/* {creatorProfile?.id === dataUser?.id && (
            <div className='highlight-wrap'>
              <div className='d-flex'>
                <svg
                  width={20}
                  height={20}
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M5.9375 8.75C7.4908 8.75 8.75 7.4908 8.75 5.9375C8.75 4.3842 7.4908 3.125 5.9375 3.125C4.3842 3.125 3.125 4.3842 3.125 5.9375C3.125 7.4908 4.3842 8.75 5.9375 8.75Z'
                    stroke='#1A1A1A'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M14.0625 8.75C15.6158 8.75 16.875 7.4908 16.875 5.9375C16.875 4.3842 15.6158 3.125 14.0625 3.125C12.5092 3.125 11.25 4.3842 11.25 5.9375C11.25 7.4908 12.5092 8.75 14.0625 8.75Z'
                    stroke='#1A1A1A'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M5.9375 16.875C7.4908 16.875 8.75 15.6158 8.75 14.0625C8.75 12.5092 7.4908 11.25 5.9375 11.25C4.3842 11.25 3.125 12.5092 3.125 14.0625C3.125 15.6158 4.3842 16.875 5.9375 16.875Z'
                    stroke='#1A1A1A'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M14.0625 16.875C15.6158 16.875 16.875 15.6158 16.875 14.0625C16.875 12.5092 15.6158 11.25 14.0625 11.25C12.5092 11.25 11.25 12.5092 11.25 14.0625C11.25 15.6158 12.5092 16.875 14.0625 16.875Z'
                    stroke='#1A1A1A'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div className='title-highlight'>{t('homePageHeader.genre')}</div>
              </div>
              <div className='block-tags d-flex scroll-bar-custom-horizontal'>
                {dataInfoOther?.tags?.map((item, index) => (
                  <div key={index} className='' style={{ gap: '24px', whiteSpace: 'nowrap' }}>
                    <span className='tags-label'>
                      {i18n.language === 'jp' ? item?.tag : item?.tag_en}
                    </span>
                  </div>
                ))}
              </div>
              <HighLight />
            </div>
          )} */}
          {creatorProfile?.user_images?.length > 0 && (
            <div className='quick-views'>
              <HomeQuickViewImages
                initialImages={creatorProfile?.user_images}
                setShowPopupFull={setShowPopupFull}
                showPopupFull={showPopupFull}
              />
            </div>
          )}
          <div className='pding-5' id='tab_id'>
            <button
              disabled={isAdmin || isAgency || !creatorProfile}
              style={{
                //backgroundColor: isFan ? '#28C76F' : account_id ? '#B1B6BB' : '#28C76F',
                backgroundColor: '#DC143C',
                pointerEvents: 'auto',
              }}
              id='btn-subcribe'
              className='subscribe-btn w-100 text-center btn text-white'
              onClick={(e) => {
                if (!loading) {
                  e.preventDefault();
                  if (idHomePage?.id) {
                    dispatch(
                      getIDHomePage({
                        id: true,
                        tab: 1,
                        isMedia: idHomePage?.isMedia,
                        page: idHomePage?.page
                          ? idHomePage?.page
                          : paginationPostHome?.current_page,
                      })
                    );
                  } else {
                    dispatch(
                      getIDHomePage({
                        id: 'null',
                        tab: 1,
                        isMedia: idHomePage?.isMedia,
                        page: idHomePage?.page
                          ? idHomePage?.page
                          : paginationPostHome?.current_page,
                      })
                    );
                  }
                  onUserNavigate();
                }
              }}>
              {t('homePageHeader.subscribeHere')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageHeader;
